import { arbitrum, baseSepolia } from "viem/chains";

export const SHINKAI_NAMESPACES: Record<number, Record<string, bigint>> = {
  [baseSepolia.id]: {
    ".sep-shinkai": 0n,
  },
  [arbitrum.id]: {
    ".shinkai": 0n,
  },
};

export const DEFAULT_SHINKAI_NAMESPACE: Record<number, string> = {
  [baseSepolia.id]: ".sep-shinkai",
  [arbitrum.id]: ".shinkai",
};
