import { arbitrum, baseSepolia } from "wagmi/chains";

import { config } from "./wagmi";
import { getChainId } from "@wagmi/core";

export const shinkaiTokenAddressMap = {
  [arbitrum.id]: "0x264fa91A04Ae1B367C4aaDe05E4473fC0E1f7c2b",
  [baseSepolia.id]: "0x264fa91A04Ae1B367C4aaDe05E4473fC0E1f7c2b",
} as const;

export const shinkaiNftAddressMap = {
  [arbitrum.id]: "0x4c285bfd61fa2425a5afe640033c9f97cec4e08a",
  [baseSepolia.id]: "0x4c285bfd61fa2425a5afe640033c9f97cec4e08a",
} as const;

export const shinkaiRegistryAddressMap = {
  [arbitrum.id]: "0x425Fb20ba3874e887336aAa7f3fab32D08135BA9",
  [baseSepolia.id]: "0x425Fb20ba3874e887336aAa7f3fab32D08135BA9",
} as const;

export const getShinkaiTokenAddress = () => {
  const chainId = getChainId(config);
  return shinkaiTokenAddressMap[chainId];
};

export const getShinkaiNftAddress = () => {
  const chainId = getChainId(config);
  return shinkaiNftAddressMap[chainId];
};

export const getShinkaiRegistryAddress = () => {
  const chainId = getChainId(config);
  return shinkaiRegistryAddressMap[chainId];
};
