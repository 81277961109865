import { http } from "wagmi";
import { baseSepolia, arbitrum } from "wagmi/chains";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { env } from "./utils/envs";
import { HttpTransport } from "viem";

const transports: Record<number, HttpTransport> = {};
for (const key of Object.keys(env.REACT_APP_WAGMI_TRANSPORTS)) {
  transports[Number(key)] = http(env.REACT_APP_WAGMI_TRANSPORTS[key]);
}

export const config = getDefaultConfig({
  appName: "Shinkai",
  projectId: env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
  chains: env.REACT_APP_TESTNET ? [baseSepolia] : [arbitrum],
  transports,
});
